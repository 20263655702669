import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GrowDiamond from '../sections/growADiamond'
import GreenBar from '../sections/greenBar3'
import Designers from '../sections/eng-designers'
import Products from '../sections/content-products'
import { PageHead } from '../common'

export default ({data}) => (
  <Layout headImg={"https://s3.amazonaws.com/underwoods/header_engagement.jpg"} headSize="small">
    <SEO
    title="Engagement"
    description="Underwood's in Jacksonville, Fl and Ponte Vedra Beach has the highest quality and largest selection of diamond engagement rings in Northeast Florida. Browse our engagement rings online." />
    <PageHead>
      <h1>ENGAGEMENT</h1>
      <p>If you are reading this, then we would like to congratulate you on your pending engagement! We have been serving Jacksonville couples since 1928 and appreciate the opportunity to help educate you on diamonds and engagement rings. Underwood’s has three locations in Jacksonville and Ponte Vedra Beach, FL each with a huge selection of loose diamonds, engagement rings and wedding bands in all types of metals and styles. Our stores feature many engagement ring designers with styles that include diamond solitaire engagement rings, diamond halo engagement rings, three-stone diamond engagement rings, antique style engagement rings and custom designed engagement rings.</p>
    </PageHead>
    <GreenBar />
    <Products data={data} link="/products/engagement"/>
    <Designers />
    <GrowDiamond id="grow-diamond"/>
    <JoinUs />
  </Layout>
)

export const EngRingQuery = graphql`
  query EngPageQuery {
  postgres {
    allProductsItems(condition: {category: "ENGAGEMENT RING"}, first: 3) {
      edges {
        node {
          title
          designer
          image
        }
      }
    }
  }
}
`
