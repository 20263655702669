import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import CardWrap, { Card } from '../components/designer-cards'

export default () => (
  <StaticQuery
    query={graphql`
      query EngDesignersQuery {
        allDesignersJson(filter: {categories: {regex: "/Engagement/"}}, sort: {fields: display_order, order: DESC})  {
          edges {
            node {
              category_image
              description
              display_order
              name
            }
          }
        }
      }
      `}
      render={ data => (
        <CardWrap>
          {data.allDesignersJson.edges.map((designer, index) => {
            return  <Card key={index}  designer={designer} />
          })}
        </CardWrap>
      )}
  />
)
